import { HeadDataType } from 'components/head-data';
import { Breadcrumb } from 'components/breadcrumbs';
import {
  getTargetedUrlPropertyTypeSelection,
  HOMES_TARGETED_URL_PROPERTY_TYPE,
} from 'utils/listing-query-helper';
import {
  LocationInfo,
  ListingFilters,
  SchemaQuestion,
  PropertyInsights,
  BreadcrumbList,
  ProductSchema,
} from './types';
import formatNumber from 'utils/format-number';
import {
  createBreadcrumbList,
  createProductSchema,
  createFAQPageSchema,
} from './types/schema';
import { AVAILABLE_STATUS, NOT_AVAILABLE_OTHER_STATUS, NOT_AVAILABLE_SOLD_STATUS } from 'contexts/preferences/listing-params/types';

/**
 * Builder class for constructing head tags data.
 *
 * @example
 * ```typescript
 * const builder = new HeadTagsBuilder();
 * builder.setCanonicalUrl('https://www.zoocasa.com/toronto-on-real-estate');
 * builder.setTitle('Zoocasa', 'Toronto', { homeType: 'Houses', rental: false, status: 'Available' });
 * const headTags = builder.build();
 *
 * // headTags is now:
 * {
 *   canonicalUrl: "https://www.zoocasa.com/toronto-on-real-estate",
 *   title: "Toronto Real Estate | Zoocasa",
 * }
 */
export class HeadTagsBuilder {
  private hrefLangs: string[];
  private canonicalUrl: string;
  private schema: Record<string, unknown>[] = [];
  private title: string;
  private metaDescription: string;
  private breadcrumbList?: BreadcrumbList;
  private productSchema?: ProductSchema;
  private numberOfHomesQuestion?: SchemaQuestion;
  private averagePriceQuestion?: SchemaQuestion;
  private propertyTypeQuestion?: SchemaQuestion;

  /**
   * Builds and returns the complete head data object containing SEO metadata and schema markup.
   *
   * @returns An object containing:
   *   - canonicalUrl: The canonical URL for the page
   *   - title: The page title
   *   - metaDescription: The meta description
   *   - schema: An array of schema.org structured data objects
   *
   * @example
   * ```typescript
   * // Example return value:
   * {
   *   canonicalUrl: "https://www.zoocasa.com/toronto-on-real-estate",
   *   title: "Toronto Real Estate | Zoocasa",
   *   metaDescription: "Browse 1,234 homes for sale in Toronto. View listing photos, property details, and prices.",
   *   schema: [
   *     // BreadcrumbList schema
   *     {
   *       "@type": "BreadcrumbList",
   *       "itemListElement": [...]
   *     },
   *     // Product schema
   *     {
   *       "@type": "Product",
   *       "name": "Toronto Real Estate",
   *       "offers": {...}
   *     },
   *     // FAQ Page schema
   *     {
   *       "@type": "FAQPage",
   *       "mainEntity": [
   *         {
   *           "@type": "Question",
   *           "name": "How many homes are there for sale in Toronto?",
   *           "acceptedAnswer": {...}
   *         }
   *         // ... additional questions
   *       ]
   *     }
   *   ]
   * }
   * ```
   */
  build(): HeadDataType {
    this.buildSchema();

    return {
      canonicalUrl: this.canonicalUrl,
      title: this.title,
      metaDescription: this.metaDescription,
      schema: this.schema,
      hrefLangs: this.hrefLangs,
    };
  }

  /**
   * Sets the hrefLang's for the page.
   *
   * @param languages - The hrefLang languages to set
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   hrefLangs: ["en-ca"]
   *   // ... other properties
   * }
   * ```
   */
  setHrefLangs(languages: string[]): HeadTagsBuilder {
    this.hrefLangs = languages;
    return this;
  }

  /**
   * Sets the canonical URL for the page.
   *
   * @param url - The canonical URL to set
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   canonicalUrl: "https://www.zoocasa.com/toronto-on-real-estate"
   *   // ... other properties
   * }
   * ```
   */
  setCanonicalUrl(url: string): HeadTagsBuilder {
    this.canonicalUrl = url;
    return this;
  }

  /**
   * Sets the page title using the site name, area name, and filters.
   *
   * @param siteName - The name of the website
   * @param areaName - The name of the geographic area
   * @param filters - The applied listing filters
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   title: "Toronto Real Estate | Zoocasa"
   *   // ... other properties
   * }
   * ```
   */
  setTitle(siteName: string, areaName: string, filters: ListingFilters): HeadTagsBuilder {
    this.title = this.createTitle(siteName, areaName, filters);
    return this;
  }

  /**
   * Sets the meta description using area name, filters, and listing count.
   *
   * @param areaName - The name of the geographic area
   * @param filters - The applied listing filters
   * @param listingsCount - The total number of listings
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   metaDescription: "Browse 1,234 homes for sale in Toronto. View listing photos, property details, and prices."
   *   // ... other properties
   * }
   * ```
   */
  setMetaDescription(areaName: string, filters: ListingFilters, listingsCount: number | undefined): HeadTagsBuilder {
    this.metaDescription = this.createMetaDescription(areaName, filters, listingsCount);
    return this;
  }

  /**
   * Sets the breadcrumb list schema markup.
   *
   * @param breadcrumbs - Array of breadcrumb navigation items
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   schema: [{
   *     "@type": "BreadcrumbList",
   *     "itemListElement": [
   *       {
   *         "@type": "ListItem",
   *         "position": 1,
   *         "name": "ON",
   *         "item": "https://www.zoocasa.com/ontario-real-estate"
   *       },
   *       // ... more breadcrumb items
   *     ]
   *   },
   *   // ... other schema objects
   *   ]
   * }
   * ```
   */
  setBreadcrumbList(breadcrumbs: readonly Breadcrumb[]): HeadTagsBuilder {
    this.breadcrumbList = createBreadcrumbList(breadcrumbs);
    return this;
  }

  /**
   * Sets the product schema markup with pricing information.
   *
   * @param name - The product name (e.g., "Toronto Real Estate")
   * @param currency - The currency code (e.g., "CAD" or "USD")
   * @param highestPrice - The highest property price
   * @param lowestPrice - The lowest property price
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   schema: [{
   *     "@type": "Product",
   *     "name": "Toronto Real Estate",
   *     "offers": {
   *       "@type": "AggregateOffer",
   *       "priceCurrency": "CAD",
   *       "lowPrice": 500000,
   *       "highPrice": 2000000
   *     }
   *   },
   *   // ... other schema objects
   *   ]
   * }
   * ```
   */
  setProduct(name: string, currency: string, highestPrice: number | null, lowestPrice: number | null): HeadTagsBuilder {
    this.productSchema = createProductSchema(name, currency, highestPrice, lowestPrice);
    return this;
  }

  /**
   * Sets the FAQ schema question about the number of homes.
   *
   * @param homeType - The type of home (e.g., "Houses", "Condos")
   * @param isRental - Whether the listings are rentals
   * @param locationInfo - Location information object
   * @param listingsCount - The total number of listings
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   schema: [{
   *     "@type": "FAQPage",
   *     "mainEntity": [{
   *       "@type": "Question",
   *       "name": "How many homes are there for sale in Toronto?",
   *       "acceptedAnswer": {
   *         "@type": "Answer",
   *         "text": "There are a total of 1,234 homes for sale in Toronto, Ontario."
   *       }
   *     },
   *     // ... other questions
   *     ]
   *   }]
   * }
   * ```
   */
  setNumberOfHomesQuestion(
    homeType: string,
    isRental: boolean,
    locationInfo: LocationInfo,
    listingsCount: number
  ): HeadTagsBuilder {
    this.numberOfHomesQuestion = this.createNumberOfHomesQuestion(
      homeType,
      isRental,
      locationInfo,
      listingsCount
    );
    return this;
  }

  /**
   * Sets the FAQ schema question about average property prices.
   *
   * @param insights - Property insights data containing average prices
   * @param isRental - Whether the listings are rentals
   * @param locationInfo - Location information object
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   schema: [{
   *     "@type": "FAQPage",
   *     "mainEntity": [{
   *       "@type": "Question",
   *       "name": "What is the average listing price of a home for sale in Toronto?",
   *       "acceptedAnswer": {
   *         "@type": "Answer",
   *         "text": "The average listing price of all home types in Toronto, Ontario is $850,000."
   *       }
   *     },
   *     // ... other questions
   *     ]
   *   }]
   * }
   * ```
   */
  setAveragePriceQuestion(
    insights: PropertyInsights,
    isRental: boolean,
    locationInfo: LocationInfo
  ): HeadTagsBuilder {
    this.averagePriceQuestion = this.createAveragePriceQuestion(
      insights,
      isRental,
      locationInfo
    );
    return this;
  }

  /**
   * Sets the FAQ schema question about specific property type prices.
   *
   * @param propertyType - The type of property ("house", "townhouse", or "condo")
   * @param insights - Property insights data containing type-specific prices
   * @param locationInfo - Location information object
   * @param isRental - Whether the listings are rentals
   * @returns The builder instance for method chaining
   *
   * @example
   * Affects the following in the output:
   * ```typescript
   * {
   *   schema: [{
   *     "@type": "FAQPage",
   *     "mainEntity": [{
   *       "@type": "Question",
   *       "name": "How much does a condo cost in Toronto?",
   *       "acceptedAnswer": {
   *         "@type": "Answer",
   *         "text": "The average price of a condo for sale in Toronto, Ontario is $600,000."
   *       }
   *     },
   *     // ... other questions
   *     ]
   *   }]
   * }
   * ```
   */
  setPropertyTypeQuestion(
    propertyType: 'house' | 'townhouse' | 'condo',
    insights: PropertyInsights,
    locationInfo: LocationInfo,
    isRental: boolean
  ): HeadTagsBuilder {
    this.propertyTypeQuestion = this.createPropertyTypeQuestion(
      propertyType,
      insights,
      locationInfo,
      isRental
    );
    return this;
  }

  private createSchemaQuestion(question: string, answer: string): SchemaQuestion {
    return {
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    };
  }

  private createNumberOfHomesQuestion(
    homeType: string,
    isRental: boolean,
    locationInfo: LocationInfo,
    listingsCount: number
  ): SchemaQuestion {
    const { place, cityAndProvince } = locationInfo;
    const forSaleOrRent = isRental ? 'rent' : 'sale';
    return this.createSchemaQuestion(
      `How many ${homeType.toLowerCase()} are there for ${forSaleOrRent} in ${place}?`,
      `There are a total of ${formatNumber(listingsCount)} ${homeType.toLowerCase()} for ${forSaleOrRent} in ${cityAndProvince}.`
    );
  }

  private createAveragePriceQuestion(
    insights: PropertyInsights,
    isRental: boolean,
    locationInfo: LocationInfo
  ): SchemaQuestion | null {
    const averagePrice = insights?.averagePrices?.total;
    if (!averagePrice) return null;
    const { place, cityAndProvince } = locationInfo;
    return this.createSchemaQuestion(
      `What is the average listing price of a home for ${isRental ? 'rent' : 'sale'} in ${place}?`,
      `The average listing price of all home types in ${cityAndProvince} is $${formatNumber(averagePrice)}.`
    );
  }

  private createPropertyTypeQuestion(
    propertyType: 'house' | 'townhouse' | 'condo',
    insights: PropertyInsights,
    locationInfo: LocationInfo,
    isRental: boolean
  ): SchemaQuestion | null {
    const averagePrice = insights.averagePrices[propertyType];
    const count = insights.counts[propertyType];
    if (!averagePrice || !count) return null;
    const { place, cityAndProvince } = locationInfo;
    return this.createSchemaQuestion(
      `How much does a ${propertyType} cost in ${place}?`,
      `The average price of a ${propertyType} for ${isRental ? 'rent' : 'sale'} in ${cityAndProvince} is $${formatNumber(averagePrice)}.`
    );
  }

  private createTitle(siteName: string, areaName: string, filters: ListingFilters): string {
    const { rental: isRental, status } = filters;
    const homeTypeLabel = getTargetedUrlPropertyTypeSelection(filters.homeType);
    let soldOrRentedStatusText = '';
    let activeOrPastStatusText = '';
    if (status === NOT_AVAILABLE_SOLD_STATUS && isRental) {
      soldOrRentedStatusText = 'Recently Rented ';
    }
    else if (status === NOT_AVAILABLE_SOLD_STATUS && !isRental) {
      soldOrRentedStatusText = 'Recently Sold ' ;
    }
    
    if (status === AVAILABLE_STATUS && isRental) {
      activeOrPastStatusText = ' for Rent';
    }
    else if (status === AVAILABLE_STATUS && !isRental) {
      activeOrPastStatusText = ' for Sale';
    }
    else if (status === NOT_AVAILABLE_OTHER_STATUS) {
      activeOrPastStatusText = ' Past Listings';
    }

    let homeTypeText = '';
    if (status !== NOT_AVAILABLE_OTHER_STATUS) {
      homeTypeText = homeTypeLabel !== HOMES_TARGETED_URL_PROPERTY_TYPE ? `${homeTypeLabel}` : 'Real Estate & Homes ';
    }
    return `${soldOrRentedStatusText}${areaName} ${homeTypeText}${activeOrPastStatusText} | ${siteName}`;
  }

  private createMetaDescription(areaName: string, filters: ListingFilters, listingsCount: number | undefined): string {
    const { rental: isRental, status } = filters;
    const homeTypeLabel = getTargetedUrlPropertyTypeSelection(filters.homeType);
    const statusText = status === NOT_AVAILABLE_SOLD_STATUS ? 'sold ' : '';
    const homeTypeText = homeTypeLabel.toLowerCase();
    const totalListings = listingsCount > 0 ? `${formatNumber(listingsCount)} ` : '';
    return `Browse ${totalListings}${statusText}${homeTypeText} for ${isRental ? 'rent' : 'sale'} in ${areaName}. View listing photos, property details, and prices.`;
  }

  private buildSchema(): void {
    this.schema = [];

    if (this.breadcrumbList) {
      this.schema.unshift(this.breadcrumbList);
    }

    if (this.productSchema) {
      this.schema.push(this.productSchema);
    }

    const questions: (SchemaQuestion | null)[] = [];

    if (this.numberOfHomesQuestion) {
      questions.push(this.numberOfHomesQuestion);
    }

    if (this.averagePriceQuestion) {
      questions.push(this.averagePriceQuestion);
    }

    if (this.propertyTypeQuestion) {
      questions.push(this.propertyTypeQuestion);
    }

    if (questions.length > 0) {
      const faqSchema = createFAQPageSchema(questions);
      this.schema.push(faqSchema);
    }
  }
}
