import { HOMES_TARGETED_URL_PROPERTY_TYPE,
  REAL_ESTATE_TARGETED_URL_PROPERTY_TYPE,
  getTargetedUrlPropertyTypeSelection,
  isForSaleOrRent,
} from 'utils/listing-query-helper';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { AVAILABLE_STATUS, Filter, NOT_AVAILABLE_SOLD_STATUS } from 'contexts/preferences/listing-params/types';

/**
 * Generates a formatted heading for the area listings page based on various filter criteria and area information.
 *
 * The function creates different heading formats depending on:
 * 1. Property status (Available, Sold, Rental)
 * 2. Property type (House, Townhouse, Condo, Farm, Commercial, Land, or general Real Estate)
 * 3. Area name
 * 4. Whether it's a rental or sale listing
 *
 * The heading logic follows these rules:
 * - For available properties:
 *   Format: "[Property Type] [For Sale/For Rent] in [Area Name]"
 *   Example: "Houses For Sale in Downtown Toronto"
 *
 * - For sold/rented properties:
 *   Format: "[Area Name] Recently [Sold/Rented] [Property Type]"
 *   Example: "Downtown Toronto Recently Sold Houses"
 *
 * - When no listings are found (fallback address):
 *   Appends "/ 0 Listings Found" to the end of the heading
 *
 * The property type is determined by checking the homeType filter object:
 * - Single property type selected: Uses specific type (Houses, Townhouses, Condos, etc.)
 * - Multiple types or none selected: Uses "Real Estate"
 *
 * @param areaName The name of the geographical area for the listings (e.g., "Downtown Toronto")
 * @param filter An object containing filter criteria:
 *   - rental Boolean indicating if these are rental listings
 *   - status Current status of the listings (AVAILABLE_STATUS, NOT_AVAILABLE_SOLD_STATUS, etc.)
 *   - homeType Object containing boolean flags for different property types: house, townhouse, condo, farm, commercial, land
 * @param isFallbackArea Boolean indicating if no listings were found for the original search criteria
 *
 * @returns A formatted string representing the page heading with proper capitalization
 *
 * @example
 * // Available properties
 * generateHeading("Downtown Toronto", { rental: false, status: "Available", homeType: { house: true } })
 * // Returns: "Houses For Sale in Downtown Toronto"
 *
 * @example
 * // Sold properties
 * generateHeading("Downtown Toronto", { rental: false, status: "Sold", homeType: { condo: true } })
 * // Returns: "Downtown Toronto Recently Sold Condos"
 *
 * @example
 * // No listings found -- "0 Listings Found" will be added to the title below this line in the front-end
 * generateHeading("Downtown Toronto", { rental: false, status: "Available", homeType: { house: true } })
 * // Returns: "Houses For Sale in Downtown Toronto"
 */
export default function generateHeading(areaName: string, filter: Pick<Filter, 'rental' | 'status' | 'homeType'>) {
  const forSaleOrRent = isForSaleOrRent(filter.rental);
  const homeTypeSelection = getTargetedUrlPropertyTypeSelection(filter.homeType);
  const homeType = homeTypeSelection === HOMES_TARGETED_URL_PROPERTY_TYPE ? REAL_ESTATE_TARGETED_URL_PROPERTY_TYPE : homeTypeSelection;

  if (filter.status !== AVAILABLE_STATUS) {
    let type = filter.status === NOT_AVAILABLE_SOLD_STATUS ? 'Sold' : '';
    if (filter.rental) {
      type = 'Rental';
    }
    if (type == '') {
      return capitalizeWords(`${areaName} Past Listings`);
    } else {
      return capitalizeWords(`${areaName} Recently ${filter.rental ? 'Rented' : 'Sold'} ${homeType}`);
    }
  } else {
    let message: string;
    if (areaName) {
      message = `${homeType} ${forSaleOrRent} in ${areaName}`;
    } else {
      message = `${homeType} ${forSaleOrRent}`;
    }

    return capitalizeWords(message);
  }
}
