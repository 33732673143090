import {
  AddressType,
  AreaPageListing,
  AreaPageListingResponse,
  SingleAddress,
} from '@zoocasa/go-search';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import {
  AREA_LISTINGS_ROUTE,
} from 'components/dynamic-page/route-matchers';
import { CANADA_ADDRESS, US_ADDRESS } from 'constants/address';
import { AREA_PAGE_FOURTH_MAP_CARD_COOKIE_NAME } from 'constants/cookies';
import { CACHE_CONTROL_HEADER_NAME, CACHE_CONTROL_HEADER_VALUE } from 'constants/headers';
import { AREA_PAGE_CONSTANTS } from 'constants/pagination';
import { FeaturesType } from 'contexts';
import {
  AddressHierarchyPath,
  createAddressHierarchyPath,
  parseAddressPath,
  ParsedAddressPath,
  ParsedLocation,
} from 'data/addresses';
import { Breadcrumb, createBreadcrumbs } from 'components/breadcrumbs';
import { DEFAULT_LISTING_PARAMS_FILTER_SHARED } from 'contexts/preferences/listing-params/defaults';
import { ASCENDING_ORDER, sortBy } from 'data/listing';
import { SearchByAreaFilterType } from 'data/search/area/types';
import deepmerge from 'deepmerge';
import { cloneDeep } from 'lodash';
import { ThemeName, themes } from 'themes';
import { CountryCode, CountryCodeList } from 'types/countries';
import { Nullable, NullableType } from 'types/nullable';
import { Themes as Tenants, ThemeNames } from 'types/themes';
import { getStringFromRequestCookie } from 'utils/cookies';
import isUserAgentCrawler from 'utils/crawler-agent';
import { removeUndefined } from 'utils/objects';
import { ProvinceAndState, ProvinceOrStateCode } from 'utils/province_or_state';
import { getSiteLocationFromRequest } from 'utils/site-location';
import { getSortBy } from 'utils/sort';
import generateCityFooterData, { generateNeighbourhoodFooterData } from '../area-listings-page/generate-dynamic-footer-data';
import generateHeading from './util/generate-heading';
import { InsightsApiType } from 'data/insights';
import { generateCanonicalUrl } from 'utils/urls/canonicalUrls';
import { HeadDataType } from 'components/head-data';
import { HeadTagsBuilder, PropertyInsights, buildHeadTags } from './util/head-tags-builder';

import { NOT_AVAILABLE_SOLD_STATUS, Sort } from 'contexts/preferences/listing-params/types';
import type { SearchApiType } from 'data/search';
import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import type { DynamicPageServerSideProps } from 'types/dynamic_page_types';
import type { AreaInsightsData, AreaListingsPageViewModel } from './area_listings_page_view_model';
import type { NamedContent, NamedContentsApiType } from 'data/named-content';
import type { AddressesApiType } from 'data/addresses';
import type { SeoLinksApiType } from 'data/seo-links';

//#region Types
export type AreaListingsPageServerSideProps = Required<Nullable<AreaListingsPageViewModel>>;

type GetServerSidePropsReturnType = Promise<GetServerSidePropsResult<DynamicPageServerSideProps<AreaListingsPageServerSideProps>>>;

export type AreaPageListings = {
  /** The address used to fetch listings. */
  electedAddress?: SingleAddress;
  /** The listings fetched from the search API. */
  areaPageListingResponse?: AreaPageListingResponse;
}

//#endregion

//#region Constants
const DEFAULT_NOT_FOUND_RESPONSE: GetServerSidePropsResult<DynamicPageServerSideProps<AreaListingsPageServerSideProps>> = { notFound: true };
const UNSUPPORTED_ADDRESS_TYPES: Readonly<AddressType[]> = [AddressType.ADDRESS_TYPE_ADDRESS, AddressType.UNRECOGNIZED, AddressType.ADDRESS_TYPE_UNSPECIFIED] as const;
//#endregion

async function getListings(addresses: SingleAddress[], filter: SearchByAreaFilterType, sort: Sort, pageNumber: number, pageSize: number, searchApi: SearchApiType): Promise<AreaPageListings | undefined> {
  if (addresses && addresses?.length > 0) {
    const apiFilter = cloneDeep(deepmerge(DEFAULT_LISTING_PARAMS_FILTER_SHARED, filter));
    for (let index = addresses.length - 1; index >= 0; index--) { // We start from the most specific address and work our way up to the least specific
      const address = addresses[index];

      const country = address.countryCode;
      const provinceOrState = address.provinceOrState || '';
      const city = address.subDivision || '';
      const neighbourhood = address.neighbourhood || '';
      const areaPageListingResponse = await searchApi.searchByArea(country, provinceOrState, city, neighbourhood, apiFilter, filter.tenant, getSortBy(sort), pageNumber, pageSize);

      if (areaPageListingResponse.TotalCount > 0) {
        return {
          electedAddress: address,
          areaPageListingResponse,
        };
      }
    }
  }
  return undefined;
}

export default async function getServerSideProps(context: GetServerSidePropsContext, features: FeaturesType, tenant: Tenants, searchApi: SearchApiType, addressesApi: AddressesApiType, namedContentsApi: NamedContentsApiType, insightsApi: InsightsApiType, seoLinksApi: SeoLinksApiType): GetServerSidePropsReturnType {
  context.res.setHeader(CACHE_CONTROL_HEADER_NAME, CACHE_CONTROL_HEADER_VALUE);

  const showMapCardExperimentView = Boolean(getStringFromRequestCookie(AREA_PAGE_FOURTH_MAP_CARD_COOKIE_NAME, context.req, 'false'));
  const siteCountry = getSiteLocationFromRequest(context.req);
  const parsedAreaPathFromUrl = parseAddressPath(context.resolvedUrl); // This is the URl path as a parsed hierarchical representation of the requested addresses

  if (parsedAreaPathFromUrl.length === 0) {
    return DEFAULT_NOT_FOUND_RESPONSE; // If the area path is empty, we don't know what the user is looking for and we should return a 404.
  }

  // There's only one case where this path will have more than one ParsedAddressPath element and it only happens when we cannot infer if
  // the url was pointing to a neighbourhood or an address. This should have been handled by our router matcher and at this point we should
  // have only areas (cities, neighbourhoods, provinces/states, countries) and can safely ignore segments that are address types.
  const requestedAddress = (parsedAreaPathFromUrl.length === 1) ? parsedAreaPathFromUrl[0] : parsedAreaPathFromUrl.filter(segment => segment.type === AddressType.ADDRESS_TYPE_NEIGHBOURHOOD)[0];
  const requestedAreaSlug: string = requestedAddress.slug;

  const partialFilter: SearchByAreaFilterType = Object.freeze({ ...requestedAddress.filter, tenant: tenant });
  const fullFilter = Object.freeze(cloneDeep(deepmerge(DEFAULT_LISTING_PARAMS_FILTER_SHARED, partialFilter)));
  const isSoldFilterOn = fullFilter.status === NOT_AVAILABLE_SOLD_STATUS;

  const sort: Sort = requestedAddress.sort;
  const page: number = Math.min(requestedAddress.page, AREA_PAGE_CONSTANTS.MAX_PAGE_COUNT);
  const pageSize: number = showMapCardExperimentView ? AREA_PAGE_CONSTANTS.DEFAULT_PAGE_SIZE - 1 : AREA_PAGE_CONSTANTS.DEFAULT_PAGE_SIZE;

  const requestedAreaName = requestedAddress.neighbourhood?.name || requestedAddress.subDivision?.name || requestedAddress.provinceOrState?.name || requestedAddress.country?.name || '';

  const requestedAreaPageAddress = await addressesApi.getAddressesBySlug(requestedAreaSlug);
  let fallbackAreaPageAddress: NullableType<SingleAddress[]> = null;
  let electedAreaPageAddress: SingleAddress[];

  const isUnknownAddress = requestedAreaPageAddress?.length === 0;
  if (isUnknownAddress) {
    // The requested address cannot be found, we need to fallback to a different address.
    const addressProperties: (keyof ParsedAddressPath)[] = ['neighbourhood', 'subDivision', 'provinceOrState', 'country'];
    for (const property of addressProperties) {
      if (requestedAddress[property]) {
        const addressSegment = requestedAddress[property] as ParsedLocation;
        const address = await addressesApi.getAddressesBySlug(addressSegment.slug);
        if (address?.length > 0) {
          fallbackAreaPageAddress = address;
          break;
        }
      }
    }
    if (fallbackAreaPageAddress?.length === 0) {
      fallbackAreaPageAddress = [siteCountry === CountryCodeList.UNITED_STATES ? US_ADDRESS : CANADA_ADDRESS];
    }
    electedAreaPageAddress = fallbackAreaPageAddress;
  } else {
    electedAreaPageAddress = requestedAreaPageAddress;
  }

  const electedAreaPageAddressHierarchy = createAddressHierarchyPath(electedAreaPageAddress);
  if (!electedAreaPageAddressHierarchy) {
    return DEFAULT_NOT_FOUND_RESPONSE; // If we cannot create an address hierarchy path, we don't know what the user is looking for and we should return a 404.
  }

  if (UNSUPPORTED_ADDRESS_TYPES.includes(electedAreaPageAddressHierarchy.type)) {
    console.error(`area page received a request to display an address that is not supported: ${requestedAreaSlug}`);
    return DEFAULT_NOT_FOUND_RESPONSE; // If the requested area is a listing address we should not try to open in an area page. This should never happen. This is a bug.
  }

  let getListingsResponse = await getListings(electedAreaPageAddress, partialFilter, sort, page, pageSize, searchApi);

  if (!getListingsResponse) {
    if (fallbackAreaPageAddress && electedAreaPageAddress[electedAreaPageAddress.length - 1] !== fallbackAreaPageAddress[fallbackAreaPageAddress.length - 1]) {
      // The address exist but we cannot find any listings, we try to find listings for the fallback address if we have one
      getListingsResponse = await getListings(fallbackAreaPageAddress, partialFilter, sort, page, pageSize, searchApi);
    }

    if (!getListingsResponse) { // The address exists but we cannot find any listings, we return an empty response
      getListingsResponse = { areaPageListingResponse: { TotalPages: 0, TotalCount: 0, PageNumber: 0, PageSize: 0, data: []}, electedAddress: electedAreaPageAddress[electedAreaPageAddress.length - 1] };
    }
  }

  const { areaPageListingResponse, electedAddress } = getListingsResponse;
  const { TotalPages, TotalCount, PageNumber, PageSize, data: listings } = areaPageListingResponse || { TotalPages: 0, TotalCount: 0, PageNumber: 0, PageSize: 0, data: []};
  const listingsSortedByPrice = sortBy(listings, (listing: AreaPageListing) => listing?.price, ASCENDING_ORDER);
  const lowestPrice = listingsSortedByPrice?.[0]?.price;
  const highestPrice = listingsSortedByPrice?.[listingsSortedByPrice.length -1 ]?.price;

  const isFallbackArea = requestedAreaSlug != electedAddress.slug;
  const breadcrumbs: readonly Breadcrumb[] | undefined = createBreadcrumbs(electedAreaPageAddressHierarchy);

  const heading = generateHeading(requestedAreaName, fullFilter);
  const expandGuidesByDefault = isUserAgentCrawler(context.req.headers);

  const internalLinks = await getInternalLinks(electedAreaPageAddressHierarchy, fullFilter.latitude, fullFilter.longitude, tenant, isSoldFilterOn) || [];
  const seoLinks = await getSeoLinks(electedAddress, seoLinksApi, isSoldFilterOn);

  let areaGuideData: AreaInsightsData | null = null;

  if (!isUnknownAddress) {
    const address = electedAreaPageAddress[electedAreaPageAddress.length - 1];
    const country = address.countryCode as CountryCode;
    const provinceOrState = address.provinceOrState?.toLowerCase() as ProvinceOrStateCode;
    const city = address.subDivision;

    const topCities = await insightsApi.getTopCities(country, provinceOrState);
    const cityStats = await insightsApi.getCityStats(country, provinceOrState, city);

    areaGuideData = {
      areaNamedContent: null,
      areaBlurb: null,
      areaBlurbHeading: null,
      areaInsightsData: {
        areaData: cityStats,
        surroundingCities: topCities?.map(city => ({
          city: city.Name,
          province: city.Province,
          slug: city.Slug,
        })),
      },
    };

    const hasHomeTypeFilter = partialFilter.homeType !== undefined;
    let namedContents: NamedContent[] | undefined;

    if (hasHomeTypeFilter) {
      namedContents = await namedContentsApi.getAreaPageNamedContent(requestedAreaSlug, fullFilter.homeType);
      if (namedContents?.length === 0) {
        // If we don't have any named content for the home type, we fallback to the generic named content
        namedContents = await namedContentsApi.getAreaPageNamedContent(requestedAreaSlug);
      }
    } else {
      namedContents = await namedContentsApi.getAreaPageNamedContent(requestedAreaSlug);
    }

    if (namedContents?.length > 0) {
      const namedContent = namedContents[0];
      const hasAreaBlurb = namedContent.content.includes('DESCRIPTION BREAK');
      const splitNamedContentData = hasAreaBlurb ? namedContent.content.split('<p>DESCRIPTION BREAK</p>') : namedContent.content;
      const areaBlurb = hasAreaBlurb ? splitNamedContentData[0] : null;
      const areaNamedContent = hasAreaBlurb ? splitNamedContentData[1]?.split('<p>SECTION BREAK</p>') : (splitNamedContentData as string).split('<p>SECTION BREAK</p>');

      areaGuideData = {
        ...areaGuideData,
        areaNamedContent: (areaNamedContent || []).map((content: string) => content.replace(/\n/g, '')),
        areaBlurb,
        areaBlurbHeading: generateAreaBlurbHeading(requestedAddress?.subDivision?.name, requestedAddress?.neighbourhood?.name, requestedAddress?.provinceOrState?.name),
        areaInsightsData: {
          areaData: null,
          surroundingCities: [],
        },
      };
    }
  }

  const theme = themes[tenant];
  // SEO experiment: on the /houses sub-variant on area pages, make the canonical url point back to the default area page
  let canonicalUrl = generateCanonicalUrl(context.resolvedUrl, theme.schemaUrl, fullFilter);
  if (requestedAddress.seoFilters?.homeType === 'houses') {
    canonicalUrl = canonicalUrl.replace('/houses', '');
  }

  let headTags: HeadDataType | null = null;
  const hrefLangs = [`${context.locale}-ca`.toLowerCase(), `${context.locale}-us`.toLowerCase()];

  if (isUnknownAddress) {
    const provOrState = requestedAddress?.provinceOrState;
    const subDivision = requestedAddress?.subDivision;
    const neighbourhood = requestedAddress?.neighbourhood;
    const isInsideProvinceOrState = !!subDivision && !!provOrState;
    const areaName = neighbourhood && isInsideProvinceOrState ? `${neighbourhood?.name}, ${provOrState?.name}` : neighbourhood?.name
    || subDivision && isInsideProvinceOrState ? `${subDivision?.name}, ${provOrState?.name}` : subDivision?.name
    || requestedAddress.provinceOrState?.name
    || requestedAddress.country?.name
    || '';
    headTags = new HeadTagsBuilder()
      .setCanonicalUrl(canonicalUrl)
      .setTitle(theme.name, areaName, fullFilter)
      .setMetaDescription(areaName, fullFilter, TotalCount)
      .setHrefLangs(hrefLangs)
      .build();
  } else if (isFallbackArea) {
    const address = requestedAreaPageAddress[requestedAreaPageAddress.length - 1];
    const isInsideProvinceOrState = !!address.subDivision && !!address.provinceOrState;
    const areaName = isInsideProvinceOrState ? `${address.label}, ${address.provinceOrState}` : address.label;
    headTags = new HeadTagsBuilder()
      .setCanonicalUrl(canonicalUrl)
      .setTitle(theme.name, areaName, fullFilter)
      .setMetaDescription(areaName, fullFilter, TotalCount)
      .setBreadcrumbList(breadcrumbs)
      .setHrefLangs(hrefLangs)
      .build();
  } else {
    const isInsideProvinceOrState = !!electedAddress.subDivision && !!electedAddress.provinceOrState;
    const areaName = isInsideProvinceOrState ? `${electedAddress.label}, ${electedAddress.provinceOrState}` : electedAddress.label;
    const country = electedAddress.countryCode as CountryCode;
    const provinceOrState = electedAddress.provinceOrState?.toLowerCase() as ProvinceOrStateCode;
    const city = electedAddress.subDivision;
    const neighbourhood = electedAddress.neighbourhood;

    let propertyInsights: PropertyInsights | null = null;
    if (country && provinceOrState) { // County stats have not been implemented yet
      const cityStats = await insightsApi.getCityStats(country, provinceOrState, city);
      if (cityStats) {
        propertyInsights = {
          averagePrices: {
            condo: cityStats.AvgCondoPrice,
            house: cityStats.AvgHousePrice,
            townhouse: cityStats.AvgTownhousePrice,
            total: cityStats.AvgTotalPrice,
          },
          counts: {
            condo: cityStats.TotalCondos,
            house: cityStats.TotalHouses,
            townhouse: cityStats.TotalTownhouses,
          },
          totalListings: cityStats.TotalCondos + cityStats.TotalHouses + cityStats.TotalTownhouses,
        };
      }
    }

    headTags = buildHeadTags({
      canonicalUrl,
      location: {
        areaName,
        country,
        administrativeArea: provinceOrState,
        city,
        neighbourhood,
      },
      filters: fullFilter,
      propertyInsights,
      siteName: theme.name,
      lowestPrice,
      highestPrice,
      hrefLangs,
    });
  }

  return {
    props: {
      routeName: AREA_LISTINGS_ROUTE,
      props: {
        features,
        tenant,
        headTags: removeUndefined(headTags),
        requestedAddress: removeUndefined(requestedAddress),
        electedAddress: removeUndefined(electedAddress),
        breadcrumbs: breadcrumbs || [],
        listings: listings.map<AreaPageListing>((listing: AreaPageListing) => AreaPageListing.toJSON(listing) as AreaPageListing),
        heading,
        pagination: {
          page: PageNumber,
          size: PageSize,
          count: TotalCount,
          total: Math.min(AREA_PAGE_CONSTANTS.MAX_PAGE_COUNT, TotalPages),
        },
        areaGuideData: areaGuideData ? removeUndefined(areaGuideData) : null,
        internalLinks,
        seoLinks: seoLinks,
        expandGuidesByDefault,
        showMapCardExperimentView,
        myLinkMyLead: tenant === ThemeNames.EXP_REALTY_US || false,
      },
    },
  };
}

//#region Utility Functions

async function getInternalLinks(address: AddressHierarchyPath, lat: number, long: number, tenant: ThemeName, isSoldFilterOn: boolean) {
  if (address.type === AddressType.ADDRESS_TYPE_SUB_DIVISION) {
    const slug = address.slug;
    const city = capitalizeWords(address.subDivision.label);
    const province = address.provinceOrState.provinceOrState.toUpperCase() as ProvinceAndState;
    return await generateCityFooterData(city, slug, tenant, province, 10, undefined, isSoldFilterOn);
  } else if (address.type === AddressType.ADDRESS_TYPE_NEIGHBOURHOOD) {
    const city = capitalizeWords(address.subDivision.label);
    const city_slug = address.subDivision.slug;
    const neighbourhood_slug = address.slug;
    const neighbourhood = capitalizeWords(deDasherize(address.neighbourhood.label));
    const province = address.provinceOrState.provinceOrState.toUpperCase() as ProvinceAndState;
    return await generateNeighbourhoodFooterData(city, city_slug, neighbourhood, neighbourhood_slug, province, lat, long, tenant);
  }
  return []; // Return empty array as default case
}

async function getSeoLinks(electedAddress: SingleAddress, seoLinksApi: SeoLinksApiType, isSoldFilterOn: boolean) {
  switch (electedAddress.addressType) {
  case AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE:
    return await seoLinksApi.getCityLinks(electedAddress.countryCode as CountryCode, electedAddress.provinceOrState as ProvinceOrStateCode, undefined, 9000, true, isSoldFilterOn);
  case AddressType.ADDRESS_TYPE_COUNTRY:
    return await seoLinksApi.getProvinceLinks(electedAddress.countryCode as CountryCode, true, isSoldFilterOn);
  default:
    return null;
  }
}

function generateAreaBlurbHeading(city = '', neighbourhood = '', provinceOrState = '') {
  if (neighbourhood?.length > 0) {
    return `About ${neighbourhood}, ${provinceOrState}`;
  }
  if (city?.length > 0) {
    return `About ${city}, ${provinceOrState}`;
  }
  if (provinceOrState?.length > 0) {
    return `About ${provinceOrState}`;
  }
  return '';
}
//#endregion
