
import { HttpRequestMethodTypes } from 'types/HttpRequestMethodTypes';
import endpoint, { isErrorResponse } from 'utils/endpoint';
import { getCmsHost } from 'utils/host-config';
import { NamedContent } from './types';

//#region Types

/** The data structure of a named content item received from the CMS API. */
type NamedContentData<T = any> = {
    id: string;
    type: string;
    attributes: {
      key: string;
      /** NOTE: The API is incorrectly returning "markdown" for html content */
      'content-type': 'markdown' | 'html' | 'json';
      'is-page': boolean;
      title: string | null;
      'meta-description': string | null;
      'raw-content': string | null;
      content: T;
    };
};

/** The response structure of the named content API in JSONAPI format. */
type NamedContentApiResponse<T = any> = { data: NamedContentData<T>[]; };
//#endregion

/**
 * Given a key, fetch the named content from the CMS.
 *
 * @param key The key of the named content to fetch.
 * @param abortSignal An optional AbortSignal to cancel the request.
 * @param host The host to use for the request. Uses {@link getCmsHost} as default.
 *
 * @returns A promise that resolves to the named content associated with the given key.
 */
export async function fetchNamedContents<T>(key: string, abortSignal?: AbortSignal, host: string = getCmsHost()): Promise<NamedContent<T>[] | undefined> {
  const namedContentUrl =`${host}/v1/named-contents`;

  const payload = await endpoint<NamedContentApiResponse>(namedContentUrl, HttpRequestMethodTypes.GET, { filter: { key }}, undefined, abortSignal);
  if (isErrorResponse(payload)) {
    return undefined;
  }
  return payload.data.map(d => createNamedContent<T>(d));
}

function createNamedContent<T>(data: NamedContentData<T>): NamedContent<T> {
  const key = data.attributes.key;
  const title = data.attributes.title;
  const metaDescription = data.attributes['meta-description'];
  const content = data.attributes.content;
  const contentType = data.attributes['content-type'];

  return { key, title, metaDescription, content, contentType };
}